<template>
  <div>
       <div class="breadcrumbs" data-aos="fade-in">
            <div class="container">
                <h2>{{$t("reset-password")}}</h2>
            </div>
        </div>
        <section class="contact events">
            <div class="container" data-aos="fade-up">
                <div class="d-flex justify-content-center row mt-5">
                    <div class="col-lg-12 mt-5 mt-lg-0">
                       
                        <div class="card mb-3">
                            <div class="card-img">
                                <img src="/assets/img/banner1.png" alt="...">
                            </div>
                            <div class="card-body">
                                <validation-observer ref="verifyForm">
                                    <form @submit.prevent="send" class="register-form">
                                        <div class="row my-3">
                                            <div class="col-lg-2 form-group">
                                                <label for="new-password">{{$t("new-password")}}<small class="text-danger">*</small></label>
                                            </div>
                                            <div class="col-lg-10 form-group">
                                                <validation-provider rules="required|password|min:8" name="new password" #default="{ errors }" vid="new password">
                                                    <input type="password" id="new-password" v-model="form.password" class="form-control" :class="errors.length > 0 ? 'is-invalid':null"  name="new-password" :placeholder="$t('new-password')"/>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <small class="text-danger">{{errors[0] }}</small>
                                                        </div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-lg-2 form-group">
                                                <label for="confirm-password">{{$t("confirm-password")}}<small class="text-danger">*</small></label>
                                            </div>
                                            <div class="col-lg-10 form-group">
                                                <validation-provider rules="required|password|min:8" name="confirm password" #default="{ errors }" vid="confirm password">
                                                    <input type="password" id="confirm-password" v-model="form.passwordConfirmation" class="form-control" :class="errors.length > 0 ? 'is invalid':null"  name="confirm-password" :placeholder="$t('confirm-password')"/>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <small class="text-danger">{{errors[0] }}</small>
                                                        </div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-center">
                                            <div class="d-flex justify-content-center col-md-6">
                                                <button type="submit" class="danger-btn">{{$t("submit")}}</button>
                                            </div>
                                        </div>
                                    </form>
                                </validation-observer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '../utils/validations/validations'
import {toast} from "../utils/alert.util";
export default {
    components : {
        ValidationProvider,
        ValidationObserver
    },
    data(){
        return {
            required,
            form  : {}
        }
    },
    methods : {
        send(){
            this.$refs.verifyForm.validate().then(success => {
                if (success) {
                    const loading = this.$vs.loading({
                        color : "#47b2e4",
                        type : "circles",
                        text : this.$t("processing-please-wait")
                    });
                    
                    this.$http.post(`/password-reset?token=${this.$route.query.token}`, this.form)
                    .then(response => {
                        loading.close()
                        toast.show({
                            icon : "success",
                            title : this.$t(response.data.message)
                        })
                        this.$router.push({name : "Login"});    
                    }).catch(e => {
                        console.log(e)
                        loading.close();
                        toast.show({
                            icon : "error",
                            title : this.$t(e.response.data.message)
                        })
                    })

                    }
                else {
                    toast.show({
                        icon : "error",
                        title : this.$t('fields-incorrectly-filled')
                    });
                }
            })
        },
    },
}
</script>

<style scoped>
.center {
border: 5px solid #FFFF00;
display: flex;
justify-content: center;
}
</style>
